var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{staticClass:"box-card"},[_c('vab-query-form-left-panel',{attrs:{"span":24}},[_c('el-button',{staticClass:"button",attrs:{"icon":"el-icon-plus","type":"warning","size":"small"},on:{"click":_vm.handleEdit}},[_vm._v("添加商品")])],1),_c('el-table',{attrs:{"data":_vm.list},on:{"selection-change":_vm.setSelectRows}},[_c('el-table-column',{attrs:{"align":"right","label":"","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.sku_info.imgUrl)?_c('el-image',{staticClass:"commodity_pic",attrs:{"src":row.sku_info.imgUrl}}):_c('el-image',{staticClass:"commodity_pic",attrs:{"src":"#"}})]}}])}),_c('el-table-column',{attrs:{"align":"left","label":"商品","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.sku_info.name))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"sku_info.price","label":"商品原售价"}}),_c('el-table-column',{attrs:{"align":"center","prop":"price","label":"活动价"}}),_c('el-table-column',{attrs:{"align":"center","prop":"reach_condition","label":"达标条件"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.cashback.type === 0)?_c('span',[_vm._v("积分数:"+_vm._s(row.reach_condition))]):_vm._e(),(row.cashback.type == 1)?_c('span',[_vm._v("消费次数:"+_vm._s(row.reach_condition))]):_vm._e(),(row.cashback.type == 2)?_c('span',[_vm._v("消费金额数:"+_vm._s(row.reach_condition))]):_vm._e(),(row.cashback.type == 3)?_c('span',[_vm._v("第三方条件:"+_vm._s(row.reach_condition))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"participation","label":"参与人数"}}),_c('el-table-column',{attrs:{"align":"center","prop":"standards","label":"达标返现人数"}}),_c('el-table-column',{attrs:{"align":"center","label":"操作","width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleEdit(row)}}},[_vm._v("修改")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleDelete(row)}}},[_vm._v("删除")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.participationList(row)}}},[_vm._v("参与列表")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.userParticipationList(row)}}},[_vm._v("参与用户列表")])]}}])})],1),_c('el-pagination',{attrs:{"background":"","current-page":_vm.queryForm.page,"layout":_vm.layout,"page-size":_vm.queryForm.pageSize,"total":_vm.count},on:{"current-change":_vm.handleCurrentChange,"size-change":_vm.handleSizeChange}}),_c('Edit',{ref:"Edit"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }