<template>
  <el-card class="box-card">
    <vab-query-form-left-panel :span="24">
      <el-button class="button" icon="el-icon-plus" type="warning" @click="handleEdit" size="small">添加商品</el-button>
    </vab-query-form-left-panel>

    <el-table
        :data="list"
        @selection-change="setSelectRows">
      <el-table-column align="right" label="" show-overflow-tooltip>
        <template #default="{ row }">
          <el-image v-if="row.sku_info.imgUrl" :src="row.sku_info.imgUrl" class="commodity_pic"/>
          <el-image v-else src="#" class="commodity_pic"/>
        </template>
      </el-table-column>
      <el-table-column align="left" label="商品" show-overflow-tooltip>
        <template #default="{ row }">
          <div>{{ row.sku_info.name }}</div>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="sku_info.price"
          label="商品原售价">
      </el-table-column>
      <el-table-column
          align="center"
          prop="price"
          label="活动价">
      </el-table-column>
      <el-table-column
          align="center"
          prop="reach_condition"
          label="达标条件">
        <template #default="{row}">
          <span v-if="row.cashback.type === 0">积分数:{{ row.reach_condition }}</span>
          <span v-if="row.cashback.type == 1">消费次数:{{ row.reach_condition }}</span>
          <span v-if="row.cashback.type == 2">消费金额数:{{ row.reach_condition }}</span>
          <span v-if="row.cashback.type == 3">第三方条件:{{ row.reach_condition }}</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="participation"
          label="参与人数">
      </el-table-column>
      <el-table-column
          align="center"
          prop="standards"
          label="达标返现人数">
      </el-table-column>
      <el-table-column align="center" label="操作" width="300">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">修改</el-button>
          <el-button type="text" @click="handleDelete(row)">删除</el-button>
          <el-button type="text" @click="participationList(row)">参与列表</el-button>
          <el-button type="text" @click="userParticipationList(row)">参与用户列表</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit"/>
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'
import {apiGetPaymentList} from "@/request/api";

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
  },
  components: {Edit},
  props: [],
  data() {
    return {
      query_time: [],
      activeName: '1',
      imgShow: true,
      selectRows: '',
      // listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
      },
      current: 0,
      list: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    handleEdit(row) {
      if (row.id) {
        this.$router.push({
          path: 'modifyPaymentBeforeReturn',
          query: {
            id: row.id,
          },
        })
      } else {
        this.$router.push({
          path: 'modifyPaymentBeforeReturn',
        })
      }
    },
    // 参与列表跳转
    participationList(row) {
      if (row.id) {
        this.$router.push({
          path: 'participationList',
          query: {
            id: row.id,
          },
        })
      }
    },
    // 参与用户列表跳转
    userParticipationList(row) {
      if (row.id) {
        this.$router.push({
          path: 'userParticipationList',
          query: {
            id: row.id,
          },
        })
      }
    },
    // 删除
    handleDelete(row) {
      if (row.id) {
        this.$confirm('你确定要删除当前项吗', null, async () => {
          const {code, message} = await doDelete({id: row.id})
          if (code == '200') {
            this.$message.success(message)
            await this.fetchData()
          } else {
            this.$message.error(message)
            await this.fetchData()
          }
        })
      } else {
        if (this.selectRows.length > 0) {
          const id = this.selectRows.map((item) => item.id).join()
          this.$confirm('你确定要删除选中项吗', null, async () => {
            const {code, message} = await doDelete({id})
            if (code == '200') {
              this.$message.success(message)
              await this.fetchData()
            } else {
              this.$message.error(message)
              await this.fetchData()
            }
          })
        } else {
          this.$message.error('未选中任何行')
          return false
        }
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      apiGetPaymentList(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data.list
          this.count = res.data.count
        }
      })
      this.listLoading = false
    },

    // 状态
    formatterState: function (row) {
      return row.state == '0' ? '进行中'
          : row.state == '1' ? '已结束'
              : '暂无'
    },
  }
}

</script>
<style>
.commodity_pic {
  width: 60px;
  height: 60px;
}

.button {
  margin-top: 20px;
}

.el-pagination {
  text-align: center;
  margin-top: 20px;
}

.el-form-item {
  margin-bottom: 0;
}

.el-dropdown {
  margin-right: 10px;
}

.page {
  background-color: #FFF;
  padding: 20px;
  margin: 20px;
}

.el-row {
  margin-bottom: 15px;
}
</style>
