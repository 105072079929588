<template>
  <el-dialog
      :close-on-click-modal="false"
      title="查看详情"
      :visible.sync="dialogFormVisible"
      width="800px"
      @close="close"
  >
    <el-form ref="form" label-width="110px" :model="form" :rules="rules">
      <el-form-item style="margin-bottom: 20px" label="活动名称" prop="">
        <el-input v-model="form.name" size="small" :disabled="true"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动时间" prop="">
        <el-date-picker
            v-model="query_time"
            size="small"
            type="datetimerange"
            range-separator="至"
            disabled
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            align="right"
        />
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动类型" prop="">
        <el-radio-group v-model="form.type" size="small" disabled>
          <el-radio-button :label="0">积分返现</el-radio-button>
          <el-radio-button :label="1">消费次数返现</el-radio-button>
          <el-radio-button :label="2">累计消费金额达标</el-radio-button>
          <el-radio-button :label="3">第三方任务达标返现</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-show="form.type == 3" style="margin-bottom: 20px" label="登陆接口" prop="">
        <el-input v-model="login_port" size="small" :disabled="true" style="width: 35%" placeholder="请输入接口链接"/>
      </el-form-item>
      <el-form-item v-show="form.type == 3" style="margin-bottom: 20px" label="查询接口" prop="">
        <el-input v-model="query_port" size="small" :disabled="true" style="width: 35%" placeholder="请输入接口链接"/>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="达标时间周期" prop="">
        <el-radio-group v-model="form.reach_time_period_type" size="small" disabled @change="selectionStatus">
          <el-radio-button :label="0">统一有效时间</el-radio-button>
          <el-radio-button :label="1">参加后指定天数</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-show="unified" style="margin-bottom: 20px" label="统一有效时间" prop="">
        <el-date-picker
            v-model="unified_time"
            size="small"
            type="datetimerange"
            range-separator="至"
            disabled
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            align="right"
        />
      </el-form-item>
      <el-form-item v-show="appoint" style="margin-bottom: 20px" label="参加后指定天数" prop="">
        <el-input v-model="form.days" size="small" disabled>
          <template slot="append">天</template>
        </el-input>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="返现方式" prop="">
        <el-radio-group v-model="form.cashback_way" size="small" disabled>
          <el-radio-button :label="0">用户余额</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item style="margin-bottom: 20px" label="活动商品" prop="">
        <el-table v-loading="listLoading" :data="list" size="small" style="width: 70%">
          <el-table-column
              align="center"
              label="商品图片"
              prop="imgUrl"
          >
            <template #default="{row}">
              <el-image v-if="row.imgUrl" :src="row.imgUrl" class="commodity_pic"/>
              <span v-else>暂无图片</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="商品名称"
              prop="name"
          />
          <el-table-column
              align="center"
              label="库存"
              prop="stock"
          />
          <el-table-column
              align="center"
              label="商品价格"
              prop="price"
          />
          <!--          <el-table-column
                        align="center"
                        label="活动价格(元)"
                        prop="price"
                    >
                      <template slot-scope="scope">
                        <el-input type="number" v-if="scope.row.price" v-model="s_list[scope.$index].price"
                                  placeholder="请输入内容" :value="scope.row.price"/>
                        <el-input type="number" v-else v-model="s_list[scope.$index].price" placeholder="请输入内容"
                                  :value="0"/>
                      </template>
                    </el-table-column>
                    <el-table-column
                        v-if="form.type === 0"
                        align="center"
                        label="达标积分数"
                        prop="reach_condition"
                    >
                      <template slot-scope="scope">
                        <el-input type="number" v-if="scope.row.reach_condition" v-model="s_list[scope.$index].reach_condition"
                                  placeholder="请输入内容" :value="scope.row.reach_condition"/>
                        <el-input type="number" v-else v-model="s_list[scope.$index].reach_condition" placeholder="请输入内容"
                                  :value="0"/>
                      </template>
                    </el-table-column>
                    <el-table-column
                        v-if="form.type == 1"
                        align="center"
                        label="达标消费次数"
                        prop="reach_condition"
                    >
                      <template slot-scope="scope">
                        <el-input type="number" v-if="scope.row.reach_condition" v-model="s_list[scope.$index].reach_condition"
                                  placeholder="请输入内容" :value="scope.row.reach_condition"/>
                        <el-input type="number" v-else v-model="s_list[scope.$index].reach_condition" placeholder="请输入内容"
                                  :value="0"/>
                      </template>
                    </el-table-column>
                    <el-table-column
                        v-if="form.type == 2"
                        align="center"
                        label="达标消费金额"
                        prop="reach_condition"
                    >
                      <template slot-scope="scope">
                        <el-input type="number" v-if="scope.row.reach_condition" v-model="s_list[scope.$index].reach_condition"
                                  placeholder="请输入内容" :value="scope.row.reach_condition"/>
                        <el-input type="number" v-else v-model="s_list[scope.$index].reach_condition" placeholder="请输入内容"
                                  :value="0"/>
                      </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        label="返现金额%"
                        prop="cashback_ratio"
                    >
                      <template slot-scope="scope">
                        <el-input type="number" v-if="scope.row.cashback_ratio" v-model="s_list[scope.$index].cashback_ratio"
                                  placeholder="请输入内容" :value="scope.row.cashback_ratio"/>
                        <el-input type="number" v-else v-model="s_list[scope.$index].cashback_ratio" placeholder="请输入内容"
                                  :value="0"/>
                      </template>
                    </el-table-column>-->
        </el-table>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close" size="small">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {apiGetPaymentMessage} from "@/request/api";
import {formatDate} from "@/utils/theTimeStamp";

export default {
  name: 'Edit',
  data() {
    return {
      query_port: '',
      login_port: '',
      query_time: [],
      unified_time: [],
      thirdParty: false,
      listLoading: false,
      unified: true,
      appoint: false,
      list: [],
      form: {},
      rules: {},
      title: '查看详情',
      dialogFormVisible: false,
    }
  },
  created() {
  },
  methods: {
    // 选择达标时间周期
    selectionStatus(event) {
      console.log(event)
      if (event == '1') {
        this.unified = false
        this.appoint = true
        this.unified_time = []
      } else {
        this.unified = true
        this.appoint = false
        this.days = ''
      }
    },
    showEdit(row) {
      this.list = []
      if (!row) {
        this.title = '添加'
      } else {
        const tempObj = Object.assign({}, row)
        this.list.push(tempObj.sku_info)
        this.getDetails(tempObj.cashback.id)
      }
      this.dialogFormVisible = true
    },
    getDetails(id) {
      apiGetPaymentMessage({id: id}).then(res => {
        if (res.code == 200) {
          console.log(res.data)
          const tempObj = res.data.cashback
          this.form = {
            id: tempObj.id,
            name: tempObj.name,
            begin_time: this.formatDate(tempObj.begin_time),
            end_time: this.formatDate(tempObj.end_time),
            type: tempObj.type,
            cashback_way: tempObj.cashback_way,
            reach_time_period_type: tempObj.reach_time_period_type,
            reach_begin_time: this.formatDate(tempObj.reach_begin_time),
            reach_end_time: this.formatDate(tempObj.reach_end_time),
            days: tempObj.days,
          }
          this.query_time = [this.formatDate(tempObj.begin_time), this.formatDate(tempObj.end_time)]
          if (res.data.cashbackGoods.reach_time_period_type != 1) {
            this.unified_time = [this.formatDate(tempObj.reach_begin_time), this.formatDate(tempObj.reach_end_time)]
          } else {
            this.unified_time = []
          }
        }
      })
    },
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
  },
}
</script>
